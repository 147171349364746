import { createSelector } from "reselect";
import { sortBy } from "lodash";

export const facilitiesSelector = state => state.facilities.all;

export const getFacilityPayersSelector = facilityId =>
  createSelector(
    state => state.payers.all,
    payers =>
      payers &&
      sortBy(
        Object.values(payers).filter(
          payer => payer.Facility === `Facility-${facilityId}`
        ),
        payer => payer.PccName
      )
  );

export const asListSelector = selector =>
  createSelector(
    selector,
    normalizedData => {
      return normalizedData && Object.values(normalizedData);
    }
  );

export const etlRunsForDateSelector = state =>
  (state.etlRuns.byDate[state.etlRuns.searchDate] || []).map(
    etlRunId => state.etlRuns.all[etlRunId]
  );

export const etlErrorsByEtlRunSelector = (state, etlRunId) => {
  const etlErrorIds = state.etlErrors.byEtlRun[etlRunId];
  if (typeof etlErrorIds === "object") {
    return etlErrorIds.map(etlErrorId => state.etlErrors.all[etlErrorId]);
  }

  return [];
};

export const sortedPlanMCCEsSelector = planGroupId =>
  createSelector(
    state => state.mcces.byPlanGroup[planGroupId],
    mcces =>
      mcces &&
      Object.values(mcces).sort((mcceA, mcceB) =>
        mcceA.Description.toLowerCase().localeCompare(
          mcceB.Description.toLowerCase()
        )
      )
  );

export function insurancePlansByGroupSelector(state, groupId) {
  return Object.values(state.insurancePlans.all).filter(
    plan => plan.InsurancePlanGroup === groupId
  );
}

export function insurancePlanGroupsByPayerSelector(state, payerId) {
  return sortBy(
    Object.values(state.insurancePlanGroups.all).filter(
      plan => plan.Payer === payerId
    ),
    plan => plan.Rank
  );
}
