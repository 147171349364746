import React, { Component } from "react";
import { withAuthenticator } from "aws-amplify-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import AnalyticsReportPage from "./AnalyticsList";
import FacilitiesTable from "./FacilitiesTable";
import Menu from "./Menu";
import EtlRunsTable from "./EtlRunsTable";
import FacilityPage from "./FacilityPage";
import EtlRunPage from "./EtlRunPage";
import PayerPage from "./PayerPage";
import InsurancePlanGroupPage from "./InsurancePlanGroupPage";
import UsersPage from "./UsersPage";

class App extends Component {
  doLink(path) {
    const { baseRoute = "" } = this.props;
    return `${baseRoute}${path}`;
  }

  render() {
    return (
      <Router>
        <>
          <Menu />
          <Route path={this.doLink("/")} exact component={EtlRunsTable} />
          <Route path={this.doLink("/etlRuns/:id")} component={EtlRunPage} />
          <Route
            path={this.doLink("/insurance")}
            exact
            component={FacilitiesTable}
          />
          <Route
            path={this.doLink("/insurance/:id")}
            component={FacilityPage}
          />
          <Route path={this.doLink("/payers/:id")} component={PayerPage} />
          <Route
            path={this.doLink("/insurancePlanGroups/:id")}
            component={InsurancePlanGroupPage}
          />

          <Route path={this.doLink("/users")} component={UsersPage} />

          <Route
            path={this.doLink("/analytics")}
            component={AnalyticsReportPage}
          />
        </>
      </Router>
    );
  }
}

export default withAuthenticator(App, true);
