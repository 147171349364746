import React from "react";
import { Button } from "semantic-ui-react";

export default function ButtonLink({ className = "", ...props }) {
  return (
    <Button
      basic
      compact
      color="blue"
      className={`link ${className}`}
      {...props}
    />
  );
}
