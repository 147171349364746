import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Message } from "semantic-ui-react";
import { Form, Button } from "formik-semantic-ui";
import { etlRunsPost } from "./store/actions/index";
import moment from "moment";
import { Auth } from "aws-amplify";
import { object, number, string } from "yup";

const initialValues = {
  facilityId: "",
  reportType: "",
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
};

export class NewEtlRun extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Modal trigger={<Button positive>New EtlRun</Button>} closeIcon>
        <Modal.Header>Create a new EtlRun</Modal.Header>
        <Modal.Content>
          <Form
            initialValues={initialValues}
            validationSchema={object({
              facilityId: number()
                .integer()
                .positive()
                .required(),
              reportType: string().required(),
              startDate: string().required(),
              endDate: string().required(),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                const authToken = (await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken();
                authToken && (await this.props.etlRunsPost(values));
                this.setState({ success: true, ...values });
                resetForm(initialValues);
              } catch (err) {
                console.error(err);
                this.setState({
                  success: false,
                  error: err,
                });
                setSubmitting(false);
              }
            }}
            schema={{
              facilityId: {
                label: "Facility",
                inputProps: { placeholder: "2" },
              },
              reportType: {
                label: "Report Type",
                inputProps: { placeholder: "adt" },
              },
              startDate: {
                label: "Start Date",
              },
              endDate: {
                label: "End Date",
              },
              downloadOnly: {
                type: "checkbox",
                label: "Just download to s3, skip parse",
                labelPosition: "right",
              },
            }}
          >
            <Button.Submit>Submit</Button.Submit>
          </Form>
          {this.state.success && (
            <Message success>
              <Message.Header>Etl runs successfully scheduled</Message.Header>
              <Message.Content>
                <p>Facility Id: {this.state.facilityId}</p>
                <p>Report Type: {this.state.reportType}</p>
                <p>
                  From {this.state.startDate} through {this.state.endDate}
                </p>
              </Message.Content>
            </Message>
          )}
          {!this.state.success && this.state.error && (
            <Message error>
              <Message.Header>
                Failed with {this.state.error.status} -{" "}
                {this.state.error.statusText}
              </Message.Header>
              <Message.Content>
                <pre style={{ overflow: "scroll" }}>{this.state.error.msg}</pre>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  { etlRunsPost }
)(NewEtlRun);
