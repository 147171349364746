import React from "react";
import { connect } from "react-redux";
import DeleteUser from "./DeleteUser";
import EditUser from "./EditUser";
import { Modal } from "semantic-ui-react";
import ButtonLink from "./common/ButtonLink";

export function UserPage({ userEmail, user = {} }) {
  if (userEmail && user) {
    return (
      user.email && (
        <Modal
          trigger={<ButtonLink className="ui link">{user.email}</ButtonLink>}
          closeIcon
        >
          <Modal.Header>Update {user.email}</Modal.Header>
          <Modal.Content>
            <>
              <h3>{user.email}</h3>
              <DeleteUser username={user.username} />
              <EditUser username={user.username} />
              <p>
                <b>Facilities: </b>
                {user.facilityIds && user.facilityIds.join(",")}
              </p>
              <p>
                <b>Enabled? </b>
                {user.isEnabled ? "Yes" : "No"}
              </p>
              <p>
                <b>Status: </b>
                {user.status}
              </p>
            </>
          </Modal.Content>
        </Modal>
      )
    );
  }

  return null;
}

function mapStateToProps(state, initialProps) {
  const { userEmail } = initialProps;
  const user =
    Object.values(state.users.all).filter(
      user => userEmail === user.email
    )[0] || {};
  return {
    userEmail: userEmail,
    user: user,
  };
}

export default connect(mapStateToProps)(UserPage);
