import { FETCH_ETL_ERRORS } from "../actions/types";
import { normalize } from "./index";

const initialState = {
  byEtlRun: {},
  all: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_ETL_ERRORS}_FULFILLED`:
      const normalized = normalize(action.payload);
      var newState = {
        ...state,
        all: {
          ...state.all,
          ...normalized,
        },
      };
      action.payload.forEach(etlError => {
        if (!newState.byEtlRun.hasOwnProperty(etlError.EtlRun)) {
          newState.byEtlRun[etlError.EtlRun] = [];
        }
        newState.byEtlRun[etlError.EtlRun].push(etlError.Id);
      });
      return newState;
    default:
      return state;
  }
}
