import { apiClient } from "./apiClient";
import {
  CREATE_BILLING_ITEM,
  CREATE_INSURANCE_PLAN_GROUP,
  CREATE_USER,
  CREATE_THERAPY_RULE,
  DELETE_ETL_RUN,
  DELETE_BILLING_ITEM,
  DELETE_INSURANCE_PLAN_GROUP,
  DELETE_USER,
  DELETE_THERAPY_RULE,
  FETCH_BILLING_ITEMS,
  FETCH_ETL_ERRORS,
  FETCH_ETL_RUNS,
  FETCH_FACILITIES,
  FETCH_INSURANCE_PLANS,
  FETCH_INSURANCE_PLAN_GROUPS,
  FETCH_INSURANCE_PLAN_GROUP,
  FETCH_PAYERS,
  UPDATE_PAYER,
  FETCH_THERAPY_RULE,
  NOOP,
  POST_ETL_RUN,
  SET_ETL_RUNS_SEARCH_DATE,
  FETCH_MCCES,
  CREATE_MCCE,
  DELETE_MCCE,
  UPDATE_MCCE,
  FETCH_INSURANCE_PLAN,
  FETCH_PAYER,
  FETCH_USERS,
  UPDATE_USER,
  UPDATE_INSURANCE_PLAN,
  UPDATE_THERAPY_RULE,
  UPDATE_INSURANCE_PLAN_GROUP,
} from "./types";
import { API } from "aws-amplify";
import { apiAccessName, apiEtlName, apiName } from "../../aws_config";

export * from "./InternalAnalytics";

function getDefaultAdditionalParams(authToken) {
  return {
    headers: {
      authorization: authToken,
      Accept: "application/json, text/plain, */*",
    },
    queryParams: {},
  };
}

export function getDateStr(dateObj) {
  const monthStr = `0${dateObj.getUTCMonth() + 1}`.slice(-2);
  const dayStr = `0${dateObj.getUTCDate()}`.slice(-2);
  return `${dateObj.getUTCFullYear()}-${monthStr}-${dayStr}`;
}

export function setEtlRunsSearchDate(newDate) {
  return {
    type: SET_ETL_RUNS_SEARCH_DATE,
    payload: Promise.resolve(newDate),
  };
}

export function facilitiesGet(authToken) {
  return {
    type: FETCH_FACILITIES,
    payload: apiClient.facilitiesGet(
      {},
      {},
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function etlRunsGet(date) {
  return {
    type: FETCH_ETL_RUNS,
    payload: API.get(apiEtlName, `/etlRuns`, {
      headers: {
        Accept: "application/json, text/plain, */*",
      },
      queryStringParameters: {
        Date: date,
      },
    }),
  };
}

export function setDateThenGetEtlRuns(newDate) {
  return dispatch => {
    const response = dispatch(setEtlRunsSearchDate(newDate));
    response.then(data => {
      dispatch(etlRunsGet(getDateStr(data.action.payload)));
    });
  };
}

export function getPayersForFacility(authToken, facilityId) {
  return {
    type: FETCH_PAYERS,
    payload: API.get(apiName, "/payers", {
      queryStringParameters: { facilityId: `Facility-${facilityId}` },
    }),
  };
}

export function getPayer(authToken, payerId) {
  return {
    type: FETCH_PAYER,
    payload: apiClient.payersPayerIdGet(
      { payerId },
      {},
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function updatePayer(authToken, payerId, payerBody) {
  return {
    type: UPDATE_PAYER,
    payload: apiClient.payersPayerIdPut(
      { payerId: payerId },
      payerBody,
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function billingItemsGet(authToken, payerId) {
  return {
    type: FETCH_BILLING_ITEMS,
    payload: apiClient.billingItemsGet(
      { payerId },
      {},
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function etlRunsRunIdErrorsGet(runId) {
  return {
    type: FETCH_ETL_ERRORS,
    payload: API.get(apiEtlName, `/etlRuns/${runId}/errors`),
  };
}

export function etlRunsRunIdDelete(runId) {
  return {
    type: DELETE_ETL_RUN,
    payload: API.del(apiEtlName, `/etlRuns/${runId}`),
  };
}

export function etlRunsPost(body) {
  return {
    type: POST_ETL_RUN,
    payload: API.post(apiEtlName, `/etlRuns`, { body }),
  };
}

export function getInsurancePlan(authToken, planId) {
  return {
    type: FETCH_INSURANCE_PLAN,
    payload: apiClient.insurancePlansPlanIdGet(
      { planId },
      {},
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function getInsurancePlanGroupInsurancePlans(
  authToken,
  insurancePlanGroupId
) {
  try {
    return {
      type: FETCH_INSURANCE_PLANS,
      payload: apiClient.insurancePlansGet(
        { insurancePlanGroupId: encodeURIComponent(insurancePlanGroupId) },
        {},
        getDefaultAdditionalParams(authToken)
      ),
    };
  } catch (err) {
    // have to do this to have errors be thrown.
    // Otherwise, execution just stops inside the SDK
    // (silent failure)
    console.error(err);
    return {
      type: NOOP,
      payload: Promise.resolve(null),
    };
  }
}

export function updateInsurancePlan(authToken, insurancePlanId, body) {
  return {
    type: UPDATE_INSURANCE_PLAN,
    payload: apiClient.insurancePlanPlanIdPut(
      { planId: insurancePlanId },
      body,
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function getPayerInsurancePlanGroups(authToken, payerId) {
  try {
    return {
      type: FETCH_INSURANCE_PLAN_GROUPS,
      payload: apiClient.insurancePlanGroupsGet(
        { payerId: encodeURIComponent(payerId) },
        {},
        getDefaultAdditionalParams(authToken)
      ),
    };
  } catch (err) {
    // have to do this to have errors be thrown.
    // Otherwise, execution just stops inside the SDK
    // (silent failure)
    console.error(err);
    return {
      type: NOOP,
      payload: Promise.resolve(null),
    };
  }
}

export function getInsurancePlanGroup(authToken, groupId) {
  try {
    return {
      type: FETCH_INSURANCE_PLAN_GROUP,
      payload: apiClient.insurancePlanGroupsPlanGroupIdGet(
        { planGroupId: encodeURIComponent(groupId) },
        {},
        getDefaultAdditionalParams(authToken)
      ),
    };
  } catch (err) {
    // have to do this to have errors be thrown.
    // Otherwise, execution just stops inside the SDK
    // (silent failure)
    console.error(err);
    return {
      type: NOOP,
      payload: Promise.resolve(null),
    };
  }
}

export function createBillingItem(authToken, body) {
  try {
    return {
      type: CREATE_BILLING_ITEM,
      payload: apiClient.billingItemsPost(
        {},
        body,
        getDefaultAdditionalParams(authToken)
      ),
    };
  } catch (err) {
    console.error(err);
    return {
      type: NOOP,
      payload: Promise.resolve(null),
    };
  }
}

export function deleteBillingItem(authToken, billingItem) {
  return {
    type: DELETE_BILLING_ITEM,
    payload: apiClient
      .billingItemsBillingItemIdDelete(
        { billingItemId: billingItem.Id },
        {},
        getDefaultAdditionalParams(authToken)
      )
      .then(() => {
        return {
          data: billingItem,
        };
      }),
  };
}

export function getMCCEs(authToken, planGroupId) {
  return {
    type: FETCH_MCCES,
    payload: apiClient
      .mccesGet(
        { planGroupId: encodeURIComponent(planGroupId) },
        {},
        getDefaultAdditionalParams(authToken)
      )
      .then(response => {
        return {
          data: response.data,
          planGroupId,
        };
      }),
  };
}

export function createMCCE(authToken, body) {
  return {
    type: CREATE_MCCE,
    payload: apiClient.mccesPost(
      {},
      body,
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function deleteMCCE(authToken, mcce) {
  return {
    type: DELETE_MCCE,
    payload: apiClient
      .mccesMcceIdDelete(
        { mcceId: mcce.Id },
        {},
        getDefaultAdditionalParams(authToken)
      )
      .then(() => {
        return {
          data: mcce,
        };
      }),
  };
}

export function updateMCCE(authToken, mcceId, mcceBody) {
  return {
    type: UPDATE_MCCE,
    payload: apiClient.mccesMcceIdPut(
      { mcceId: mcceId },
      mcceBody,
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function usersGet() {
  return {
    type: FETCH_USERS,
    payload: API.get(apiAccessName, `/users`),
  };
}

export function userPost(body) {
  return {
    type: CREATE_USER,
    payload: API.post(apiAccessName, `/user`, { body }),
  };
}

export function userUserIdDelete(userId) {
  return {
    type: DELETE_USER,
    payload: API.del(apiAccessName, `/user/${userId}`),
  };
}

export function userUserIdPut(userId, body) {
  return {
    type: UPDATE_USER,
    payload: API.put(apiAccessName, `/user/${userId}`, { body }),
  };
}

export function insurancePlanGroupPost(authToken, body) {
  return {
    type: CREATE_INSURANCE_PLAN_GROUP,
    payload: apiClient.insurancePlanGroupsPost(
      {},
      body,
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function insurancePlanGroupPlanGroupIdDelete(authToken, planGroupId) {
  return {
    type: DELETE_INSURANCE_PLAN_GROUP,
    payload: apiClient.insurancePlanGroupsPlanGroupIdDelete(
      { planGroupId },
      {},
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function updateInsurancePlanGroup(planGroupId, body) {
  return {
    type: UPDATE_INSURANCE_PLAN_GROUP,
    payload: API.put(apiName, `/insurancePlanGroups/${planGroupId}`, { body }),
  };
}

export function therapyRulePost(authToken, body) {
  return {
    type: CREATE_THERAPY_RULE,
    payload: apiClient.therapyRulesPost(
      {},
      {
        ...body,
        NumDays: parseInt(body.NumDays),
        NumMinutes: parseInt(body.NumMinutes),
      },
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function therapyRuleGet(authToken, planGroupId) {
  return {
    type: FETCH_THERAPY_RULE,
    payload: apiClient.therapyRulesTherapyRuleIdGet(
      { therapyRuleId: encodeURIComponent(`TherapyRule-${planGroupId}`) },
      {},
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function therapyRulePut(authToken, therapyRuleId, body) {
  return {
    type: UPDATE_THERAPY_RULE,
    payload: apiClient.therapyRulesTherapyRuleIdPut(
      { therapyRuleId },
      {
        NumDays: parseInt(body.NumDays),
        NumMinutes: parseInt(body.NumMinutes),
      },
      getDefaultAdditionalParams(authToken)
    ),
  };
}

export function therapyRuleDelete(authToken, therapyRuleId) {
  return {
    type: DELETE_THERAPY_RULE,
    payload: apiClient
      .therapyRulesTherapyRuleIdDelete(
        { therapyRuleId },
        {},
        getDefaultAdditionalParams(authToken)
      )
      .then(() => {
        return {
          data: therapyRuleId,
        };
      }),
  };
}

// export function analyticsReportCreate() {
//   return {
//     type: CREATE_NEW_REPORT,
//   };
// }
//
// export function analyticsReportDelete() {
//   return {
//     type: DELETE_ANALYTICS_REPORT,
//   };
// }
//
// export function analyticsReportLinksCreate() {
//   return {
//     type: ADD_REPORT_LINKS,
//   };
// }
//
// export function analyticsReportLinksDelete() {
//   return {
//     type: DELETE_REPORT_LINKS,
//   };
// }
//
// export function analyticsReportLinksGet() {
//   return {
//     type: FETCH_REPORT_LINKS,
//   };
// }
