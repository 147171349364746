import { FETCH_REPORT_LINKS } from "../actions/types";
import { normalize } from "./index";

const initialState = {
  data: {},
};

export default function(state = initialState, { type, payload }) {
  console.log(payload);
  switch (type) {
    case `${FETCH_REPORT_LINKS}_FULFILLED`:
      return {
        data: normalize(payload.data, { key: "id" }),
      };
    default:
      return state;
  }
}
