import { Auth } from "aws-amplify";
import authConfig from "./aws_exports";

export const apiName = "InternalToolsApi";
export const apiAccessName = "InternalAccessApi";
export const apiEtlName = "InternalEtlApi";
export const apiAnalyticsName = "InternalAnalyticsApi";

const custom_header = async () => {
  return {
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
  };
};

export const config = {
  Auth: {
    region: process.env.REACT_APP_USER_POOL_REGION || authConfig.region,
    userPoolId: process.env.REACT_APP_USER_POOL_ID || authConfig.userPoolId,
    userPoolWebClientId:
      process.env.REACT_APP_USER_POOL_CLIENT_ID || "6nlrfktc6mdknmefq4e0c2o30b",
  },
  API: {
    endpoints: [
      {
        name: apiAnalyticsName,
        endpoint:
          process.env.REACT_APP_INTERNAL_ANALYTICS_API_BASE_URL ||
          "https://api.internal-analytics.medasync-dev.com",
        custom_header,
      },
      {
        name: apiAccessName,
        endpoint:
          process.env.REACT_APP_API_ACCESS_URL ||
          "https://api.access-internal.medasync-dev.com",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
      {
        name: apiEtlName,
        endpoint:
          process.env.REACT_APP_API_ETL_URL ||
          "https://api.etl-internal.medasync-dev.com",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
      {
        name: apiName,
        endpoint:
          process.env.REACT_APP_API_BASE_URL ||
          "https://api.internal.medasync-dev.com",
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
    ],
  },
};
