import { FETCH_PAYERS, FETCH_PAYER, UPDATE_PAYER } from "../actions/types";

const initialState = {
  all: {},
};

export default function(state = initialState, { type, payload = [] }) {
  switch (type) {
    case `${FETCH_PAYERS}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_PAYERS}_FULFILLED`:
      const newState = {
        ...state,
        isLoading: false,
      };
      payload.forEach(payer => {
        newState.all[payer.Id] = payer;
      });
      return newState;
    case `${FETCH_PAYERS}_REJECTED`:
      return state;
    case `${UPDATE_PAYER}_FULFILLED`:
    case `${FETCH_PAYER}_FULFILLED`:
      const { data = {} } = payload;
      return {
        ...state,
        all: {
          ...state.all,
          [data.Id]: data,
        },
      };
    default:
      return state;
  }
}
