import { Auth } from "aws-amplify";
import React from "react";
import { connect } from "react-redux";
import { Dropdown, Message, Modal } from "semantic-ui-react";
import { Form, Button } from "formik-semantic-ui";
import { object, string } from "yup";
import { updateInsurancePlan } from "./store/actions";
import { insurancePlanGroupsByPayerSelector } from "./store/selectors";

class MoveInsurancePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newInsurancePlanGroup: null };
  }

  handleNewValue(value) {
    this.setState({
      ...this.state,
      newInsurancePlanGroup: value,
    });
  }

  render() {
    const { insurancePlan, groupsForPayer } = this.props;
    const initialValues = {
      InsurancePlanGroup: insurancePlan.InsurancePlanGroup,
    };
    return (
      <Modal trigger={<Button>Move</Button>} closeIcon>
        <Modal.Header>Move Insurance Plan {insurancePlan.PlanId}</Modal.Header>
        <Modal.Content>
          <Form
            initialValues={initialValues}
            validationSchema={object({
              InsurancePlanGroup: string().required(),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                const authToken = (await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken();
                const realValues =
                  this.state.newInsurancePlanGroup === null
                    ? values
                    : {
                        ...values,
                        InsurancePlanGroup: this.state.newInsurancePlanGroup,
                      };
                await this.props.updateInsurancePlan(
                  authToken,
                  insurancePlan.Id,
                  realValues
                );
                this.setState({
                  success: true,
                  ...realValues,
                  newInsurancePlanGroup: null,
                });
                resetForm(initialValues);
              } catch (err) {
                console.error(err);
                this.setState({
                  success: false,
                  error: err,
                  newInsurancePlanGroup: null,
                });
                setSubmitting(false);
              }
            }}
            render={() => (
              <>
                <Form.Children>
                  <Dropdown
                    placeholder="select plan group"
                    fluid
                    selection
                    options={groupsForPayer.map(({ Id, Name }, index) => ({
                      key: Id,
                      text: Name,
                      value: Id,
                    }))}
                    onChange={(e, { value }) => this.handleNewValue(value)}
                  />
                </Form.Children>
                <Button.Submit>Move</Button.Submit>
              </>
            )}
          />
          {this.state.success && (
            <Message success>
              <Message.Header>Insurance plan moved successfully</Message.Header>
              <Message.Content>
                <p>Plan: {this.props.insurancePlan.PlanId}</p>
                <p>New Group: {this.state.groupName}</p>
              </Message.Content>
            </Message>
          )}
          {!this.state.success && this.state.error && (
            <Message error>
              <Message.Header>
                Failed with {this.state.error.status} -{" "}
                {this.state.error.statusText}
              </Message.Header>
              <Message.Content>
                <pre style={{ overflow: "scroll" }}>{this.state.error.msg}</pre>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state, initialProps) => {
  const { planId, payerId } = initialProps;
  return {
    groupsForPayer: insurancePlanGroupsByPayerSelector(state, payerId),
    insurancePlan: state.insurancePlans.all[planId],
  };
};

export default connect(
  mapStateToProps,
  { updateInsurancePlan }
)(MoveInsurancePlan);
