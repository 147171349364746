import Amplify from "aws-amplify";
import devApigClientFactory from "@medasync/dev-internal-sdk";
import prodApigClientFactory from "@medasync/prod-internal-sdk";
import { config } from "../../aws_config";

const apigClientFactory =
  window.location.hostname === "internal.medasync.com"
    ? prodApigClientFactory
    : devApigClientFactory;

Amplify.configure(config);

export const apiClient = apigClientFactory.newClient({
  region: "us-east-2",
});
