import {
  ADD_REPORT_LINKS,
  CREATE_NEW_REPORT,
  DELETE_ANALYTICS_REPORT,
  DELETE_REPORT_LINKS,
  FETCH_REPORT_LINKS,
} from "./types";
import { API } from "aws-amplify";

const apiName = "InternalAnalyticsApi";
export const defaultInit = { response: true };

export function addReportLinks(authToken, data) {
  return {
    type: ADD_REPORT_LINKS,
    payload: API.post(apiName, `/analytics/reportlinks/add`, {
      ...defaultInit,
      body: data,
    }),
  };
}

export function createAnalyticsReport(authToken, data) {
  return {
    type: CREATE_NEW_REPORT,
    payload: API.post(apiName, `/analytics/reports/create`, {
      ...defaultInit,
      body: data,
    }),
  };
}

export function deleteAnalyticsReport(authToken, data) {
  console.log(data);
  return {
    type: DELETE_ANALYTICS_REPORT,
    payload: API.post(apiName, `/analytics/reports/delete/${data}`, {
      ...defaultInit,
      body: { reportId: data },
    }),
  };
}

export function fetchReportLinks() {
  return {
    type: FETCH_REPORT_LINKS,
    payload: API.get(apiName, `/analytics/reportlinks/all`, defaultInit).then(
      ({ data }) => ({ data })
    ),
  };
}

export function deleteReportLinks() {
  return {
    type: DELETE_REPORT_LINKS,
    payload: API.get(
      apiName,
      `/analytics/reportlinks/delete`,
      defaultInit
    ).then(({ data }) => ({ data })),
  };
}
