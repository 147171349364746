import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { slide as Slide } from "react-burger-menu";

export class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.isMenuOpen = this.isMenuOpen.bind(this);
  }

  doLink(path) {
    const { baseRoute = "" } = this.props;
    return `${baseRoute}${path}`;
  }

  isMenuOpen = function(state) {
    this.setState({ isOpen: state.isOpen });
  };

  closeMenu() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    return (
      <Slide
        className="bm-menu"
        onStateChange={this.isMenuOpen}
        isOpen={isOpen}
      >
        <NavLink
          className="bm-item-list"
          to={this.doLink("/")}
          activeClassName="active"
          onClick={() => this.closeMenu()}
        >
          ETL
        </NavLink>
        <NavLink
          className="bm-item-list"
          to={this.doLink("/insurance")}
          activeClassName="active"
          onClick={() => this.closeMenu()}
        >
          Insurance
        </NavLink>
        <NavLink
          className="bm-item-list"
          to={this.doLink("/users")}
          activeClassName="active"
          onClick={() => this.closeMenu()}
        >
          Users
        </NavLink>
        <NavLink
          className="bm-item-list"
          to={this.doLink("/analytics")}
          activeClassName="active"
          onClick={() => this.closeMenu()}
        >
          Analytics
        </NavLink>
        <a
          className="bm-item-list"
          activeClassName="active"
          href="https://us-east-2.console.aws.amazon.com/secretsmanager/home"
          onClick={() => this.closeMenu()}
        >
          Password Manager
        </a>
      </Slide>
    );
  }
}

export default Menu;
