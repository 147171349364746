import { Auth } from "aws-amplify";
import React from "react";
import { connect } from "react-redux";
import { List } from "semantic-ui-react";
import { therapyRuleDelete } from "./store/actions";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

class DeleteTherapyRule extends React.Component {
  constructor(props) {
    super(props);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  async onDeleteConfirm() {
    let authToken;
    try {
      authToken = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken;
    } catch {
      Auth.signOut();
    }
    const { therapyRuleId, therapyRuleDelete } = this.props;
    if (authToken) {
      try {
        therapyRuleDelete(authToken, therapyRuleId);
      } catch (err) {
        console.error(err);
      }
    }
  }

  render() {
    const { therapyRuleId } = this.props;

    return (
      <List.Item>
        <List.Content floated="right">
          <DeleteConfirmationModal
            buttonProps={{ size: "small" }}
            buttonText="Therapy Rule"
            header="Are you sure you want to delete this therapy rule?"
            message={therapyRuleId}
            onDeleteConfirm={this.onDeleteConfirm}
            disabled={!therapyRuleId}
          />
        </List.Content>
        <List.Header>{therapyRuleId}</List.Header>
      </List.Item>
    );
  }
}

const mapStateToProps = (state, initialProps) => {
  const { groupId } = initialProps;
  return {
    therapyRule: state.therapyRules.byInsurancePlanGroup[groupId],
  };
};

export default connect(mapStateToProps, {
  therapyRuleDelete,
})(DeleteTherapyRule);
