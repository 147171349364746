import {
  FETCH_INSURANCE_PLANS,
  FETCH_INSURANCE_PLAN,
  UPDATE_INSURANCE_PLAN,
} from "../actions/types";
import { normalize } from "./index";

const initialState = {
  all: {},
  isLoading: false,
};

const addOneToAll = (state, data) => ({
  ...state,
  all: {
    ...state.all,
    [data.Id]: data,
  },
  isLoading: false,
});

export default function(state = initialState, { type, payload }) {
  const data = payload && payload.data;
  switch (type) {
    case `${FETCH_INSURANCE_PLANS}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_INSURANCE_PLANS}_FULFILLED`:
      const newState = {
        ...state,
        all: normalize(payload.data),
        isLoading: false,
      };
      return newState;
    case `${FETCH_INSURANCE_PLAN}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_INSURANCE_PLAN}_FULFILLED`:
      return addOneToAll(state, data);
    case `${UPDATE_INSURANCE_PLAN}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${UPDATE_INSURANCE_PLAN}_FULFILLED`:
      return addOneToAll(state, data);
    default:
      return state;
  }
}
