import {
  FETCH_INSURANCE_PLAN_GROUP,
  FETCH_INSURANCE_PLAN_GROUPS,
} from "../actions/types";
import { normalize } from "./index";

const initialState = {
  all: {},
};

export default function(state = initialState, { type, payload }) {
  const data = payload && payload.data;
  switch (type) {
    case `${FETCH_INSURANCE_PLAN_GROUPS}_FULFILLED`:
      return {
        ...state,
        all: normalize(data),
      };
    case `${FETCH_INSURANCE_PLAN_GROUP}_FULFILLED`:
      return {
        ...state,
        all: normalize([data]),
      };
    case `${FETCH_INSURANCE_PLAN_GROUP}_REJECTED`:
      return state;
    default:
      return state;
  }
}
