import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Form, Input, Button as FormButton } from "formik-semantic-ui";
import { object, string } from "yup";
import { updateMCCE } from "./store/actions";
import { Auth } from "aws-amplify";

export class UpdateMCCEForm extends PureComponent {
  constructor(props) {
    super(props);
    this.submitChange = this.submitChange.bind(this);
  }

  async submitChange(updated) {
    let authToken;
    try {
      authToken = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken;
    } catch {
      Auth.signOut();
    }
    const { updateMCCE, mcceId } = this.props;
    if (authToken) {
      const { description: Description, nlpId: NlpId } = updated;
      await updateMCCE(authToken, mcceId, { Description, NlpId });
    }
  }

  render() {
    const { description, nlpId, toggleEditMode } = this.props;
    const initialValues = { description, nlpId };
    return (
      <Form
        initialValues={initialValues}
        validationSchema={object({
          description: string().required(),
          nlpId: string().required(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          console.log("onSubmit");
          try {
            await this.submitChange(values);
            toggleEditMode();
          } catch (err) {
            console.log(err);
          } finally {
            setSubmitting(false);
          }
        }}
        onReset={(_values, { resetForm }) => {
          resetForm(initialValues);
          toggleEditMode();
        }}
        render={({ dirty }) => (
          <>
            <Input
              name="nlpId"
              size="mini"
              fieldProps={{
                inline: true,
                style: {
                  display: "inline",
                  marginRight: "10px",
                },
              }}
            />
            :{" "}
            <Input
              name="description"
              size="mini"
              fieldProps={{
                inline: true,
                style: {
                  display: "inline",
                  marginRight: "10px",
                },
              }}
            />
            <FormButton.Submit
              disabled={!dirty}
              positive
              size="mini"
              icon="check"
              style={{
                marginRight: "10px",
              }}
            />
            <FormButton.Reset size="mini" icon="close" />
          </>
        )}
      />
    );
  }
}

export default connect(
  null,
  { updateMCCE }
)(UpdateMCCEForm);
