import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import { Button } from "semantic-ui-react";
import Table from "@medasync/shared-ui/dist/Table";
import {
  setDateThenGetEtlRuns,
  getDateStr,
  facilitiesGet,
} from "./store/actions";
import {
  etlRunsForDateSelector,
  asListSelector,
  facilitiesSelector,
} from "./store/selectors";
import EtlRunLink from "./EtlRunLink";
import NewEtlRun from "./NewEtlRun";
import Filter from "./common/Filter";
import Paginator from "./common/Paginator";

function getConfig(baseRoute) {
  return [
    {
      title: "Sort Key",
      transformer: data => <EtlRunLink etlRun={data} />,
    },
    {
      title: "Date/Time",
      key: "Datetime",
    },
    {
      title: "DataDate",
      key: "DataDate",
    },
    {
      title: "Report Type",
      key: "ReportType",
    },
    {
      title: "Facility",
      key: "Facility",
    },
    {
      title: "Number of Records",
      key: "NumRecords",
    },
    {
      title: "Status",
      key: "Status",
    },
    {
      title: "File",
      key: "FileUri",
    },
  ];
}

const pageSize = 20;

export class EtlRunsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        facilities: [],
        reportType: [],
      },
      activePageData: [],
    };
    this.adjustDate = this.adjustDate.bind(this);
    this.getEtlRuns = this.getEtlRuns.bind(this);
    this.setActivePageData = this.setActivePageData.bind(this);
  }

  getEtlRuns(newDate) {
    const { setDateThenGetEtlRuns } = this.props;
    if (typeof setDateThenGetEtlRuns === "function") {
      Auth.currentAuthenticatedUser()
        .then(
          data =>
            data.signInUserSession.idToken.jwtToken &&
            setDateThenGetEtlRuns(newDate)
        )
        .catch(err => Auth.signOut());
    }
  }

  adjustDate(numDays) {
    var { searchDate } = this.props;
    searchDate.setDate(searchDate.getDate() + numDays);
    this.getEtlRuns(searchDate);
  }

  componentDidMount() {
    const { searchDate, facilitiesGet } = this.props;
    this.getEtlRuns(searchDate);
    if (typeof facilitiesGet === "function") {
      Auth.currentAuthenticatedUser()
        .then(data => facilitiesGet(data.signInUserSession.idToken.jwtToken))
        .catch(err => Auth.signOut());
    }
  }
  handleFilterChange(filterKey, selectedValues) {
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [filterKey]: selectedValues,
      },
    }));
  }

  setActivePageData(pageData) {
    this.setState(prevState => ({
      ...prevState,
      activePageData: pageData,
    }));
  }

  render() {
    const {
      etlRuns = [],
      searchDate,
      baseRoute = "",
      facilities = [],
    } = this.props;
    const { filters, activePageData } = this.state;
    const facilitiesOptions = facilities.map(facility => ({
      label: `${facility.Name} (Facility-${facility.Id})`,
      value: `Facility-${facility.Id}`,
    }));
    const reportOptions = [
      ...new Set(etlRuns.map(({ ReportType }) => ReportType)),
    ].map(content => ({
      label: content.charAt(0).toUpperCase() + content.slice(1),
      value: content,
    }));
    const filterOptions = {
      facilities: facilitiesOptions,
      reportType: reportOptions,
    };
    const visibleEtlRuns = etlRuns.filter(etlRun => {
      return (
        (filters.facilities.length === 0 ||
          filters.facilities
            .map(({ value }) => value)
            .includes(etlRun.Facility)) &&
        (filters.reportType.length === 0 ||
          filters.reportType
            .map(({ value }) => value)
            .includes(etlRun.ReportType))
      );
    });

    return (
      <>
        <div style={{ margin: "10px" }}>
          <Button onClick={() => this.adjustDate(-1)}>Prev</Button>
          <span style={{ margin: "0 10px" }}>{getDateStr(searchDate)}</span>
          <Button onClick={() => this.adjustDate(1)}>Next</Button>
        </div>
        <div style={{ margin: "10px" }}>
          <NewEtlRun />
          {Object.keys(filterOptions).map(filterKey => {
            const label =
              filterKey.charAt(0).toUpperCase() + filterKey.slice(1);
            return (
              <Filter
                className="panel"
                key={filterKey}
                label={label}
                value={filters[filterKey]}
                options={filterOptions[filterKey]}
                onChange={values => this.handleFilterChange(filterKey, values)}
                isMulti
              />
            );
          })}
        </div>
        <div style={{ margin: "10px" }}>
          <Table
            config={getConfig(baseRoute)}
            data={activePageData}
            rowStyler={data => {}}
          />
          <Paginator
            dataToPaginate={visibleEtlRuns}
            pageSize={pageSize}
            setActivePageData={this.setActivePageData}
          />
        </div>
      </>
    );
  }
}

EtlRunsTable.propTypes = {
  etlRuns: PropTypes.array,
  searchDate: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    etlRuns: etlRunsForDateSelector(state),
    searchDate: state.etlRuns.searchDate,
    facilities: asListSelector(facilitiesSelector)(state),
  };
}

export default connect(mapStateToProps, {
  setDateThenGetEtlRuns,
  facilitiesGet,
})(EtlRunsTable);
