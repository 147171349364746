import { FETCH_USERS, UPDATE_USER } from "../actions/types";
import { normalize } from "./index";

const initialState = {
  all: [],
  byUsername: {},
};

export default function(state = initialState, { type, payload = [] }) {
  switch (type) {
    case `${FETCH_USERS}_FULFILLED`:
      return {
        all: normalize(payload, { key: "username" }),
      };
    case `${UPDATE_USER}_FULFILLED`:
      return {
        ...state,
        all: {
          ...state.all,
          [payload.username]: payload,
        },
      };
    default:
      return state;
  }
}
