import {
  FETCH_MCCES,
  CREATE_MCCE,
  DELETE_MCCE,
  UPDATE_MCCE,
} from "../actions/types";
import { normalize } from "./index";

const initialState = {
  byPlanGroup: {},
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case `${FETCH_MCCES}_FULFILLED`:
      return {
        byPlanGroup: {
          ...state.byPlanGroup,
          [payload.planGroupId]: normalize(payload.data),
        },
      };
    case `${CREATE_MCCE}_FULFILLED`:
    case `${UPDATE_MCCE}_FULFILLED`:
    case `${DELETE_MCCE}_FULFILLED`:
      const { InsurancePlanGroup: planGroupId, Id: mcceId } = payload.data;
      const planMcces = state.byPlanGroup[planGroupId] || {};
      if (type.startsWith(DELETE_MCCE)) {
        delete planMcces[mcceId];
      } else {
        planMcces[mcceId] = payload.data;
      }
      return {
        byPlanGroup: {
          ...state.byPlanGroup,
          [planGroupId]: planMcces,
        },
      };
    default:
      return state;
  }
}
