import { combineReducers } from "redux";
import billingItemsReducer from "./billingItems";
import etlErrorsReducer from "./etlErrors";
import etlRunsReducer from "./etlRuns";
import facilitiesReducer from "./facilities";
import payersReducer from "./payers";
import insurancePlansReducer from "./insurancePlans";
import insurancePlanGroupsReducer from "./insurancePlanGroups";
import mccesReducer from "./mcces";
import usersReducer from "./users";
import therapyRulesReducer from "./therapyRules";
import analyticsReportsReducer from "./analyticsReports";

export const normalize = (data = [], { key = "Id" } = {}) => {
  return data.reduce((result, obj) => {
    return {
      ...result,
      [obj[key]]: obj,
    };
  }, {});
};

export default combineReducers({
  analyticsReports: analyticsReportsReducer,
  billingItems: billingItemsReducer,
  etlErrors: etlErrorsReducer,
  etlRuns: etlRunsReducer,
  facilities: facilitiesReducer,
  payers: payersReducer,
  insurancePlans: insurancePlansReducer,
  insurancePlanGroups: insurancePlanGroupsReducer,
  mcces: mccesReducer,
  users: usersReducer,
  therapyRules: therapyRulesReducer,
});
