import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@medasync/shared-ui/dist/Table";
import { getPayersForFacility } from "./store/actions";
import { getFacilityPayersSelector } from "./store/selectors";
import PayerLink from "./PayerLink";

export const config = [
  {
    title: "Display Name",
    key: "DisplayName",
  },
  {
    title: "Payer - Comprehend ARN",
    transformer: data => <PayerLink payerId={data.Id} />,
  },
];

/*
 * TODO pull facility out of route data
 **/
export class FacilityPage extends Component {
  async componentDidMount() {
    const { getPayersForFacility, facilityId } = this.props;
    if (typeof getPayersForFacility === "function") {
      let authToken;
      try {
        const user = await Auth.currentAuthenticatedUser();
        authToken = user.signInUserSession.idToken.jwtToken;
      } catch {
        Auth.signOut();
      }
      authToken && getPayersForFacility(authToken, facilityId);
    }
  }

  render() {
    const { payers = [], isLoading } = this.props;
    return isLoading || !payers ? (
      <h3>Loading facility payers...</h3>
    ) : payers.length ? (
      <Table config={config} data={payers} rowStyler={data => {}} />
    ) : (
      <h3>No Payers</h3>
    );
  }
}

function mapStateToProps(state, initialProps) {
  const { match: { params: { id: facilityId } = {} } = {} } = initialProps;
  return {
    facilityId,

    payers: getFacilityPayersSelector(facilityId)(state),
    isLoading: state.payers.isLoading,
  };
}

export default connect(mapStateToProps, {
  getPayersForFacility,
})(FacilityPage);
