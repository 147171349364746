import {
  FETCH_THERAPY_RULE,
  CREATE_THERAPY_RULE,
  UPDATE_THERAPY_RULE,
  DELETE_THERAPY_RULE,
} from "../actions/types";
import { normalize } from "./index";

const initialState = {
  byInsurancePlanGroup: {},
  isLoading: false,
};

export default function(state = initialState, { type, payload }) {
  const data = payload && payload.data;
  switch (type) {
    case `${FETCH_THERAPY_RULE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${CREATE_THERAPY_RULE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${UPDATE_THERAPY_RULE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${DELETE_THERAPY_RULE}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${FETCH_THERAPY_RULE}_FULFILLED`:
      return {
        byInsurancePlanGroup: {
          ...state.byInsurancePlanGroup,
          ...normalize([data], { key: "InsurancePlanGroup" }),
        },
        isLoading: false,
      };
    case `${CREATE_THERAPY_RULE}_FULFILLED`:
      return {
        byInsurancePlanGroup: {
          ...state.byInsurancePlanGroup,
          ...normalize([data], { key: "InsurancePlanGroup" }),
        },
        isLoading: false,
      };
    case `${UPDATE_THERAPY_RULE}_FULFILLED`:
      return {
        byInsurancePlanGroup: {
          ...state.byInsurancePlanGroup,
          ...normalize([data], { key: "InsurancePlanGroup" }),
        },
        isLoading: false,
      };
    case `${UPDATE_THERAPY_RULE}_REJECTED`:
      return state;
    case `${DELETE_THERAPY_RULE}_FULFILLED`:
      return {
        ...state,
        byInsurancePlanGroup: {
          [data]: {},
        },
        isLoading: false,
      };
    default:
      return state;
  }
}
