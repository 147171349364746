import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function EtlRunLink({ etlRun }) {
  if (etlRun) {
    const destination = `/etlRuns/${encodeURIComponent(etlRun.Id)}`;
    return <Link to={destination}>{etlRun.Id}</Link>;
  }

  return null;
}

function mapStateToProps(state, initialProps) {
  const { etlRun } = initialProps;
  return state.etlRuns.all[etlRun.Id];
}

export default connect(mapStateToProps)(EtlRunLink);
