import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Message } from "semantic-ui-react";
import { Form, Input, Button } from "formik-semantic-ui";
import { userUserIdPut } from "./store/actions/index";
import { Auth } from "aws-amplify";
import { array, object, number } from "yup";

export class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user } = this.props;
    const initialValues = {
      email: user.email,
      shouldReceiveEmails: user.shouldReceiveEmails || "false",
      facilityIds: JSON.stringify(user.facilityIds),
    };
    return (
      <Modal trigger={<Button>Edit User</Button>} closeIcon>
        <Modal.Header>Update {user.email}</Modal.Header>
        <Modal.Content>
          <Form
            initialValues={initialValues}
            validationSchema={object({
              facilityIds: array()
                .of(
                  number()
                    .integer()
                    .min(0)
                )
                .required(),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                const authToken = (await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken();
                authToken &&
                  (await this.props.userUserIdPut(user.username, values));
                this.setState({ success: true, ...values });
                resetForm(initialValues);
              } catch (err) {
                console.error(err);
                this.setState({
                  success: false,
                  error: err,
                });
                setSubmitting(false);
              }
            }}
            render={() => {
              const facilitiesPlaceholder =
                "[" + user.facilityIds.join(",") + "]";
              return (
                <>
                  <Form.Children>
                    <Input
                      name="facilityIds"
                      label="Facilities"
                      inputProps={{ placeholder: facilitiesPlaceholder }}
                    />
                  </Form.Children>
                  <Button.Submit>Submit</Button.Submit>
                </>
              );
            }}
          />
          {this.state.success && (
            <Message success>
              <Message.Header>User successfully updated</Message.Header>
              <Message.Content>
                <p>Facilities: {this.state.facilityIds}</p>
                <p>Email: {this.state.email}</p>
              </Message.Content>
            </Message>
          )}
          {!this.state.success && this.state.error && (
            <Message error>
              <Message.Header>
                Failed with {this.state.error.status} -{" "}
                {this.state.error.statusText}
              </Message.Header>
              <Message.Content>
                <pre style={{ overflow: "scroll" }}>{this.state.error.msg}</pre>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export function mapStateToProps(state, initialProps) {
  const { username } = initialProps;
  return {
    username: username,
    user: state.users.all[username],
  };
}

export default connect(
  mapStateToProps,
  { userUserIdPut }
)(EditUser);
