import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@medasync/shared-ui/dist/Table";
import { getPayer, getPayerInsurancePlanGroups } from "./store/actions";
import { insurancePlanGroupsByPayerSelector } from "./store/selectors";
import InsurancePlanGroupLink from "./InsurancePlanGroupLink";
import NewInsurancePlanGroup from "./NewInsurancePlanGroup";
import { Button } from "semantic-ui-react";
import UpdatePayerForm from "./UpdatePayerForm";

export const insurancePlanTableConfig = [
  {
    title: "",
    transformer: data => (
      <InsurancePlanGroupLink insurancePlanGroupId={data.Id} />
    ),
  },
];

export class PayerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
  }
  async componentDidMount() {
    const {
      payerId,
      payer,
      getPayer,
      getPayerInsurancePlanGroups,
    } = this.props;
    const authToken = (await Auth.currentAuthenticatedUser()).signInUserSession
      .idToken.jwtToken;
    !payer && typeof getPayer === "function" && getPayer(authToken, payerId);
    typeof getPayerInsurancePlanGroups === "function" &&
      getPayerInsurancePlanGroups(authToken, payerId);
  }

  toggleEditMode() {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  }

  render() {
    const { insurancePlanGroups = [], payer } = this.props;
    const { isEditMode } = this.state;
    const nonEditMode = (
      <>
        <b>Display Name: </b>
        {payer && payer.DisplayName ? payer.DisplayName : "No Display Name Set"}
        <Button
          primary
          size="mini"
          style={{ marginLeft: "10px" }}
          onClick={this.toggleEditMode}
        >
          Edit
        </Button>
      </>
    );
    const editMode = (
      <UpdatePayerForm
        payer={payer}
        toggleEditMode={this.toggleEditMode}
        updatedKey="DisplayName"
      />
    );
    return payer ? (
      <>
        <NewInsurancePlanGroup payerId={payer.Id} />
        <h3>{payer.PccName}</h3>
        {isEditMode ? editMode : nonEditMode}
        <h4>Insurance Plan Groups</h4>
        <Table
          config={insurancePlanTableConfig}
          data={insurancePlanGroups}
          rowStyler={data => {}}
        />
      </>
    ) : (
      <h3>Loading Payer Information...</h3>
    );
  }
}

function mapStateToProps(state, initialProps) {
  const { match: { params: { id: payerId } = {} } = {} } = initialProps;
  return {
    payerId: payerId,
    payer: state.payers.all[payerId],
    insurancePlanGroups: insurancePlanGroupsByPayerSelector(state, payerId),
  };
}

export default connect(mapStateToProps, {
  getPayer,
  getPayerInsurancePlanGroups,
})(PayerPage);
