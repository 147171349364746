import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Message } from "semantic-ui-react";
import { Form, Input, Button } from "formik-semantic-ui";
import { userPost } from "./store/actions/index";
import { Auth } from "aws-amplify";
import { array, object, number, string } from "yup";

const initialValues = {
  email: "",
  facilityIds: "",
  shouldReceiveEmails: "false",
};

export class NewUser extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Modal trigger={<Button positive>New User</Button>} closeIcon>
        <Modal.Header>Create a new User</Modal.Header>
        <Modal.Content>
          <Form
            initialValues={initialValues}
            validationSchema={object({
              facilityIds: array()
                .of(
                  number()
                    .integer()
                    .positive()
                )
                .min(1)
                .required(),
              email: string()
                .email()
                .required(),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                const authToken = (await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken();
                authToken && (await this.props.userPost(values));
                this.setState({ success: true, ...values });
                resetForm(initialValues);
              } catch (err) {
                console.error(err);
                this.setState({
                  success: false,
                  error: err,
                });
                setSubmitting(false);
              }
            }}
            render={() => (
              <>
                <Form.Children>
                  <Input
                    name="facilityIds"
                    label="Facilities"
                    inputProps={{ placeholder: "1,2,3" }}
                  />
                  <Input
                    name="email"
                    label="Email"
                    inputProps={{ placeholder: "me@example.com" }}
                  />
                </Form.Children>
                <Button.Submit>Submit</Button.Submit>
              </>
            )}
          />
          {this.state.success && (
            <Message success>
              <Message.Header>User successfully created</Message.Header>
              <Message.Content>
                <p>Facilities: {this.state.facilityIds}</p>
                <p>Email: {this.state.email}</p>
              </Message.Content>
            </Message>
          )}
          {!this.state.success && this.state.error && (
            <Message error>
              <Message.Header>
                Failed with {this.state.error.status} -{" "}
                {this.state.error.statusText}
              </Message.Header>
              <Message.Content>
                <pre style={{ overflow: "scroll" }}>{this.state.error.msg}</pre>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  { userPost }
)(NewUser);
