import React from "react";
import Select, { components } from "react-select";

const customFilterOption = (option, rawInput) => {
  const words = rawInput.split(" ");
  return words.reduce(
    (acc, cur) =>
      acc &&
      option.label &&
      option.label.toLowerCase().includes(cur.toLowerCase()),
    true
  );
};

export default function Filter({
  label,
  className,
  selectHeight,
  isDisabled,
  disabledPlaceholder,
  placeholder = "Select...",
  customOptions,
  onChange,
  ...rest
}) {
  const MenuList = props => {
    return (
      <components.MenuList {...props}>
        {props.children}
        {customOptions.map((customOption, i) => {
          return {
            ...customOption,
            key: `customOption${i}`,
          };
        })}
      </components.MenuList>
    );
  };
  const handleOnChange = values => {
    if (!onChange) return;
    onChange(values === null ? [] : values);
  };

  return (
    <div
      className={`${className} form-group d-flex`}
      style={{ alignItems: "baseline" }}
    >
      {label && (
        <label className="control-label flex-0-auto w-xs m-r-xs">{label}</label>
      )}
      <Select
        className="flex-1-auto"
        isDisabled={isDisabled}
        placeholder={
          isDisabled ? disabledPlaceholder || placeholder : placeholder
        }
        {...rest}
        components={!!customOptions && customOptions.length && { MenuList }}
        styles={{
          dropdownIndicator: provided => ({
            ...provided,
            padding: "0 8px",
          }),
          control: provided => ({
            ...provided,
            minHeight: selectHeight || "auto",
          }),
        }}
        filterOption={customFilterOption}
        onChange={handleOnChange}
      />
    </div>
  );
}
