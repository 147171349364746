import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Message } from "semantic-ui-react";
import { Form, Button } from "formik-semantic-ui";
import { createAnalyticsReport } from "./store/actions/InternalAnalytics";
import { Auth } from "aws-amplify";
import { object, string } from "yup";

const initialValues = {
  facilityIds: "",
  powerBiReportId: "",
  reportName: "",
};

export class NewAnalyticsReport extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Modal trigger={<Button positive>New Report</Button>} closeIcon>
        <Modal.Header>Create a new Analytics Report</Modal.Header>
        <Modal.Content>
          <Form
            initialValues={initialValues}
            validationSchema={object({
              reportName: string().required(),
              powerBiReportId: string().required(),
              facilityIds: string().required(),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                const authToken = (await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken();
                await this.props.createAnalyticsReport(authToken, values);
                this.setState({ success: true, ...values });
                resetForm(initialValues);
              } catch (err) {
                console.error(err);
                this.setState({
                  success: false,
                  error: err,
                });
                setSubmitting(false);
              }
            }}
            schema={{
              facilityIds: {
                label: "Facility IDs",
                inputProps: { placeholder: "1,2,3,4" },
              },
              powerBiReportId: {
                label: "Report ID",
                inputProps: { placeholder: "" },
              },
              reportName: {
                label: "Report Name",
                inputProps: { placeholder: "" },
              },
            }}
          >
            <Button.Submit>Submit</Button.Submit>
          </Form>
          {this.state.success && (
            <Message success>
              <Message.Header>
                Analytics Report Succesfully Created
              </Message.Header>
              <Message.Content>nice</Message.Content>
            </Message>
          )}
          {!this.state.success && this.state.error && (
            <Message error>
              <Message.Header>
                Failed with {this.state.error.status} -{" "}
                {this.state.error.statusText}
              </Message.Header>
              <Message.Content>
                <pre style={{ overflow: "scroll" }}>{this.state.error.msg}</pre>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  { createAnalyticsReport }
)(NewAnalyticsReport);
