import React, { Component } from "react";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { userUserIdPut } from "./store/actions/index";
import { Modal, Dropdown, Message } from "semantic-ui-react";
import ButtonLink from "./common/ButtonLink";
import { Form, Button } from "formik-semantic-ui";
import { object, string } from "yup";

export class UserMailingStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user } = this.props;
    const initialValues = {
      email: user.email,
      shouldReceiveEmails: user.shouldReceiveEmails,
      facilityIds: JSON.stringify(user.facilityIds),
    };
    return (
      <Modal
        trigger={
          <ButtonLink className="ui link">
            {user.shouldReceiveEmails === "true" ? "Yes" : "No"}
          </ButtonLink>
        }
        closeIcon
      >
        <Modal.Header>{`Should ${user.email} receive emails ?`}</Modal.Header>
        <Modal.Content>
          <Form
            initialValues={initialValues}
            validationSchema={object({
              shouldReceiveEmails: string().required(),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                const authToken = (await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken();
                authToken &&
                  (await this.props.userUserIdPut(user.username, values));
                this.setState({ success: true, ...values });
                resetForm(values);
              } catch (err) {
                console.error(err);
                this.setState({
                  success: false,
                  error: err,
                });
                setSubmitting(false);
              }
            }}
            render={({ values, setFieldValue }) => (
              <>
                <Form.Children>
                  <Dropdown
                    fluid
                    selection
                    defaultValue={
                      user.shouldReceiveEmails === "true" ? "true" : "false"
                    }
                    options={[
                      { text: "No", value: "false" },
                      { text: "Yes", value: "true" },
                    ]}
                    onChange={(e, { value }) => {
                      setFieldValue("shouldReceiveEmails", value);
                    }}
                  />
                </Form.Children>
                <br></br>
                <br></br>
                <Button.Submit>Update</Button.Submit>
              </>
            )}
          />
          {this.state.success && (
            <Message success>
              <Message.Header>User successfully updated</Message.Header>
              <Message.Content>
                <p>Email: {this.state.email}</p>
              </Message.Content>
            </Message>
          )}
          {!this.state.success && this.state.error && (
            <Message error>
              <Message.Header>
                Failed with {this.state.error.status} -{" "}
                {this.state.error.statusText}
              </Message.Header>
              <Message.Content>
                <pre style={{ overflow: "scroll" }}>{this.state.error.msg}</pre>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export function mapStateToProps(state, initialProps) {
  const { user: { username } = {} } = initialProps;
  return {
    user: state.users.all[username],
  };
}

export default connect(
  mapStateToProps,
  { userUserIdPut }
)(UserMailingStatus);
