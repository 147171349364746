export const CREATE_BILLING_ITEM = "CREATE_BILLING_ITEM";
export const DELETE_BILLING_ITEM = "DELETE_BILLING_ITEM";
export const FETCH_BILLING_ITEMS = "FETCH_BILLING_ITEMS";

export const POST_ETL_RUN = "POST_ETL_RUN";
export const SET_ETL_RUNS_SEARCH_DATE = "SET_ETL_RUNS_SEARCH_DATE";
export const DELETE_ETL_RUN = "DELETE_ETL_RUN";

export const FETCH_ETL_ERRORS = "FETCH_ETL_ERRORS";
export const FETCH_ETL_RUNS = "FETCH_ETL_RUNS";
export const FETCH_PAYERS = "FETCH_PAYERS";
export const FETCH_AUTH_TOKEN = "FETCH_AUTH_TOKEN";

export const NOOP = "NOOP"; // Needed for API gateway parameter validation errors

export const DELETE_MCCE = "DELETE_MCCE";
export const FETCH_MCCES = "FETCH_MCCES";
export const CREATE_MCCE = "CREATE_MCCE";
export const UPDATE_MCCE = "UPDATE_MCCE";

export const FETCH_PAYER = "FETCH_PAYER";
export const UPDATE_PAYER = "UPDATE_PAYER";

export const FETCH_FACILITIES = "FETCH_FACILITIES";
export const FETCH_INSURANCE_PLANS = "FETCH_INSURANCE_PLANS";
export const FETCH_INSURANCE_PLAN = "FETCH_INSURANCE_PLAN";
export const UPDATE_INSURANCE_PLAN = "UPDATE_INSURANCE_PLAN";

export const FETCH_INSURANCE_PLAN_GROUPS = "FETCH_INSURANCE_PLAN_GROUPS";
export const CREATE_INSURANCE_PLAN_GROUP = "CREATE_INSURANCE_PLAN_GROUP";
export const DELETE_INSURANCE_PLAN_GROUP = "DELETE_INSURANCE_PLAN_GROUP";
export const FETCH_INSURANCE_PLAN_GROUP = "FETCH_INSURANCE_PLAN_GROUP";
export const UPDATE_INSURANCE_PLAN_GROUP = "UPDATE_INSURANCE_PLAN_GROUP";

export const CREATE_USER = "CREATE_USER";
export const FETCH_USERS = "FETCH_USERS";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";

export const CREATE_THERAPY_RULE = "CREATE_THERAPY_RULE";
export const FETCH_THERAPY_RULE = "FETCH_THERAPY_RULE";
export const UPDATE_THERAPY_RULE = "UPDATE_THERAPY_RULE";
export const DELETE_THERAPY_RULE = "DELETE_THERAPY_RULE";

export const ADD_REPORT_LINKS = "ADD_REPORT_LINKS";
export const CREATE_NEW_REPORT = "CREATE_NEW_REPORT";
export const DELETE_ANALYTICS_REPORT = "DELETE_ANALYTICS_REPORT";
export const DELETE_REPORT_LINKS = "DELETE_REPORT_LINKS";
export const FETCH_REPORT_LINKS = "FETCH_REPORT_LINKS";
