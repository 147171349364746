import {
  CREATE_BILLING_ITEM,
  DELETE_BILLING_ITEM,
  FETCH_BILLING_ITEMS,
} from "../actions/types";
import { normalize } from "./index";

const initialState = {
  all: {},
  byPayer: {},
};

export default function(state = initialState, action) {
  let newState = null;
  switch (action.type) {
    case `${CREATE_BILLING_ITEM}_FULFILLED`:
      newState = { ...state };
      const newData = action.payload.data.data;
      newState.all[newData.Id] = newData;
      const byPayerSet = newState.byPayer[newData.Payer] || new Set();
      newState.byPayer[newData.Payer] = byPayerSet.add(newData.Id);
      return newState;

    case `${FETCH_BILLING_ITEMS}_FULFILLED`:
      const normalized = normalize(action.payload.data);
      newState = {
        ...state,
        all: {
          ...state.all,
          ...normalized,
        },
        byPayer: { ...state.byPayer },
      };
      action.payload.data.forEach(billingItem => {
        const byPayerSet = newState.byPayer[billingItem.Payer] || new Set();
        newState.byPayer[billingItem.Payer] = byPayerSet.add(billingItem.Id);
      });
      return newState;

    case `${DELETE_BILLING_ITEM}_FULFILLED`:
      const { Payer: payerId, Id: billingItemId } = action.payload.data;
      const billingItems = state.byPayer[payerId];
      billingItems && billingItems.delete(billingItemId);
      newState = {
        ...state,
        all: {
          ...state.all,
        },
        byPayer: { ...state.byPayer, [payerId]: billingItems },
      };
      delete newState.all[billingItemId];
      return newState;

    default:
      return state;
  }
}
