import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getInsurancePlanGroupInsurancePlans,
  getInsurancePlanGroup,
  updateInsurancePlanGroup,
  getMCCEs,
  therapyRulePost,
  therapyRuleGet,
  therapyRulePut,
} from "./store/actions";
import {
  insurancePlansByGroupSelector,
  sortedPlanMCCEsSelector,
} from "./store/selectors";
import { Auth } from "aws-amplify";
import MCCEList from "./MCCEList";
import NewMCCE from "./NewMCCE";
import { Form, Input, Button } from "formik-semantic-ui";
import { object, number, string } from "yup";
import Table from "@medasync/shared-ui/dist/Table";
import MoveInsurancePlan from "./MoveInsurancePlan";
import DeleteInsurancePlanGroup from "./DeleteInsurancePlanGroup";
import DeleteTherapyRule from "./DeleteTherapyRule";

export function getPlanTableConfig(payerId) {
  function tableTransformer(data) {
    return (
      <>
        <MoveInsurancePlan planId={data} payerId={payerId} />
      </>
    );
  }
  return [
    {
      key: "PlanId",
    },
    {
      key: "Id",
      transformer: tableTransformer,
    },
  ];
}

const initialValues = {
  NumDays: 99999,
  NumMinutes: 99999,
};

class InsurancePlanGroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const {
      groupId,
      group,
      plans,
      therapyRule,
      getInsurancePlanGroup,
      getInsurancePlanGroupInsurancePlans,
      therapyRuleGet,
      getMCCEs,
      mcces,
    } = this.props;
    if (groupId && !group) {
      const user = await Auth.currentAuthenticatedUser();
      const authToken = user.signInUserSession.idToken.jwtToken;
      getInsurancePlanGroup(authToken, groupId);
    }
    if (!therapyRule && typeof therapyRuleGet === "function") {
      const user = await Auth.currentAuthenticatedUser();
      const authToken = user.signInUserSession.idToken.jwtToken;
      therapyRuleGet(authToken, groupId);
    }
    if (
      !plans.length &&
      typeof getInsurancePlanGroupInsurancePlans === "function"
    ) {
      const user = await Auth.currentAuthenticatedUser();
      const authToken = user.signInUserSession.idToken.jwtToken;
      getInsurancePlanGroupInsurancePlans(authToken, groupId);
    }

    if (!mcces && typeof getMCCEs === "function") {
      const user = await Auth.currentAuthenticatedUser();
      const authToken = user.signInUserSession.idToken.jwtToken;
      getMCCEs(authToken, groupId);
    }
  }

  render() {
    const {
      group,
      plans,
      isLoading,
      therapyRule,
      therapyRulePut,
      therapyRulePost,
      mcces,
    } = this.props;
    const therapyRuleInitialVals = therapyRule
      ? {
          NumDays: therapyRule.NumDays,
          NumMinutes: therapyRule.NumMinutes,
        }
      : initialValues;
    const planGroupInitialVals = {
      Name: group && group.Name ? group.Name : "",
      Description: group && group.Description ? group.Description : "",
      Rank: group && typeof group.Rank === "number" ? group.Rank : 0,
    };
    return isLoading || !group ? (
      <h3>Loading...</h3>
    ) : (
      <>
        {plans.length === 0 && (
          <DeleteInsurancePlanGroup planGroupId={group.Id} />
        )}
        <h2>{group.Name}</h2>
        <Form
          initialValues={planGroupInitialVals}
          validationSchema={object({
            Name: string().required(),
            Description: string(),
            Rank: number().required(),
          })}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            try {
              const requestBody = values.Description
                ? { ...values }
                : { Name: values.Name, Rank: values.Rank };
              group && (await updateInsurancePlanGroup(group.Id, requestBody));
              this.setState({ success: true, ...values });
              resetForm({ ...values });
            } catch (err) {
              console.error(err);
              this.setState({
                success: false,
                error: err,
              });
              setSubmitting(false);
            }
          }}
          render={() => (
            <>
              <Form.Children>
                <Input
                  name="Name"
                  label="Plan Group name"
                  inputProps={{ placeholder: "My Plan Group name" }}
                />
                <Input
                  name="Description"
                  label="level description"
                  inputProps={{ placeholder: "Level description" }}
                />
                <Input
                  name="Rank"
                  label="Rank"
                  inputProps={{ placeholder: "0" }}
                />
              </Form.Children>
              <Button.Submit>Save</Button.Submit>
            </>
          )}
        />
        <h4>Insurance Plans</h4>
        {plans.length ? (
          <Table
            config={getPlanTableConfig(group.Payer)}
            data={plans}
            rowStyler={data => {}}
          />
        ) : (
          <h3>No Plans</h3>
        )}
        <Form
          initialValues={therapyRuleInitialVals}
          validationSchema={object({
            NumDays: number()
              .integer()
              .positive()
              .required(),
            NumMinutes: number()
              .integer()
              .positive()
              .required(),
          })}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            try {
              const requestBody = {
                ...values,
                InsurancePlanGroup: group.Id,
              };
              const authToken = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
              therapyRule
                ? await therapyRulePut(authToken, therapyRule.Id, requestBody)
                : await therapyRulePost(authToken, requestBody);
              this.setState({ success: true, ...values });
              resetForm({ ...values });
            } catch (err) {
              console.error(err);
              this.setState({
                success: false,
                error: err,
              });
              setSubmitting(false);
            }
          }}
          render={() => (
            <>
              <Form.Children>
                <b>THERAPY RULE: </b>
                <p>
                  {therapyRule && therapyRule.Id
                    ? `id: ${therapyRule.Id}`
                    : "Please add a therapy rule:"}
                </p>
                <Input name="NumDays" label="at least" />
                <Input name="NumMinutes" label="days with at least" /> minutes
                of therapy.
              </Form.Children>
              <Button.Submit>Save</Button.Submit>
            </>
          )}
        />
        <div className="bm-item-list">
          <DeleteTherapyRule therapyRuleId={therapyRule && therapyRule.Id} />
        </div>
        <div style={{ margin: "10px" }}>
          <NewMCCE planGroupId={group.Id} />
        </div>
        {mcces ? (
          mcces.length ? (
            <MCCEList mcces={mcces} />
          ) : (
            <h3>No MCCEs</h3>
          )
        ) : (
          <h3> Loading MCCEs</h3>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, initialProps) => {
  const { match: { params: { id: groupId } = {} } = {} } = initialProps;
  const group = state.insurancePlanGroups.all[groupId];
  return {
    groupId,
    group,
    plans: insurancePlansByGroupSelector(state, groupId),
    isLoading: state.insurancePlans.isLoading,
    therapyRule: state.therapyRules.byInsurancePlanGroup[groupId],
    isTherapyRuleLoading: state.therapyRules.isLoading,
    mcces: sortedPlanMCCEsSelector(groupId)(state),
  };
};

export default connect(
  mapStateToProps,
  {
    getInsurancePlanGroup,
    getInsurancePlanGroupInsurancePlans,
    getMCCEs,
    therapyRuleGet,
    therapyRulePost,
    therapyRulePut,
  }
)(InsurancePlanGroupPage);
