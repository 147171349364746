import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import { asListSelector, facilitiesSelector } from "./store/selectors";
import { usersGet, facilitiesGet } from "./store/actions";
import Table from "@medasync/shared-ui/dist/Table";
import NewUser from "./NewUser";
import Filter from "./common/Filter";
import { intersection } from "lodash";
import UserPage from "./UserPage";
import UserMailingStatus from "./UserMailingStatus";

export const config = [
  {
    title: "Email",
    key: "email",
    transformer: data => <UserPage userEmail={data} />,
    sortable: true,
    sortValue: data => data && data.toUpperCase().codePointAt(0),
  },
  {
    title: "Facilities",
    transformer: data => data.facilityIds && data.facilityIds.join(","),
  },
  {
    title: "Enabled?",
    transformer: data => (data.isEnabled ? "Yes" : "No"),
  },
  {
    title: "Status",
    key: "status",
  },
  {
    title: "Should receive email",
    transformer: data => <UserMailingStatus user={data} />,
  },
];

const defaultSort = {
  column: 0,
  direction: "ascending",
};

export class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilityFilters: [],
    };
  }
  componentDidMount() {
    const { usersGet, facilitiesGet } = this.props;
    if (typeof usersGet === "function") {
      Auth.currentAuthenticatedUser().then(data =>
        usersGet(data.signInUserSession.idToken.jwtToken)
      );
    }
    if (typeof facilitiesGet === "function") {
      Auth.currentAuthenticatedUser()
        .then(data => facilitiesGet(data.signInUserSession.idToken.jwtToken))
        .catch(err => Auth.signOut());
    }
  }
  setFacilityFilters(values) {
    this.setState({ facilityFilters: values });
  }

  render() {
    const { users = [], facilities = [] } = this.props;
    const { facilityFilters = [] } = this.state;
    const facilitiesOptions = facilities.map(facility => ({
      label: `${facility.Name} (${facility.Id})`,
      value: facility.Id,
    }));
    const visibleUsers = users.filter(user => {
      return (
        facilityFilters.length === 0 ||
        intersection(facilityFilters, user.facilityIds).length > 0
      );
    });

    return (
      <>
        <div style={{ margin: "10px" }}>
          <NewUser />
          <Filter
            className="panel"
            placeholder="please select a facility"
            label="Facilities"
            key="facilities"
            options={facilitiesOptions}
            onChange={options =>
              this.setFacilityFilters(options.map(opt => opt.value))
            }
            isMulti
          />
        </div>
        <Table config={config} data={visibleUsers} sort={defaultSort} />
      </>
    );
  }
}

UsersPage.propTypes = {
  users: PropTypes.array,
};

export function mapStateToProps(state) {
  return {
    users: Object.values(state.users.all),
    facilities: asListSelector(facilitiesSelector)(state),
  };
}

export default connect(mapStateToProps, { usersGet, facilitiesGet })(UsersPage);
