import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import { asListSelector, facilitiesSelector } from "./store/selectors";
import { facilitiesGet } from "./store/actions";
import Table from "@medasync/shared-ui/dist/Table";
import FacilityLink from "./FacilityLink";

export const config = [
  {
    title: "Name",
    transformer: data => <FacilityLink facilityId={data.Id} />,
  },
  {
    title: "ID",
    key: "Id",
  },
];

export class FacilitiesTable extends Component {
  componentDidMount() {
    const { facilitiesGet } = this.props;
    if (typeof facilitiesGet === "function") {
      Auth.currentAuthenticatedUser()
        .then(data => facilitiesGet(data.signInUserSession.idToken.jwtToken))
        .catch(err => Auth.signOut());
    }
  }

  render() {
    const { facilities = [] } = this.props;
    return <Table config={config} data={facilities} rowStyler={data => {}} />;
  }
}

FacilitiesTable.propTypes = {
  facilities: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    facilities: asListSelector(facilitiesSelector)(state),
  };
}

export default connect(
  mapStateToProps,
  { facilitiesGet }
)(FacilitiesTable);
