import React from "react";
import { List } from "semantic-ui-react";
import MCCEListItem from "./MCCEListItem";

export default ({ mcces }) => {
  return (
    <List bulleted divided size="big" relaxed selection>
      {mcces.map(mcce => (
        <MCCEListItem key={mcce.Id} mcce={mcce} />
      ))}
    </List>
  );
};
