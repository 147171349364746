import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Form, Input, Button as FormButton } from "formik-semantic-ui";
import { object, string } from "yup";
import { updatePayer } from "./store/actions";
import { Auth } from "aws-amplify";

export class UpdatePayerForm extends PureComponent {
  constructor(props) {
    super(props);
    this.submitChange = this.submitChange.bind(this);
  }

  async submitChange(updated) {
    let authToken;
    try {
      authToken = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken;
    } catch {
      Auth.signOut();
    }
    const { updatePayer, payer, updatedKey } = this.props;
    if (authToken) {
      await updatePayer(
        authToken,
        payer.Id,
        updated[updatedKey]
          ? updated
          : {
              [updatedKey]: null,
            }
      );
    }
  }

  render() {
    const { payer, toggleEditMode, updatedKey } = this.props;
    const initialValues = {
      [updatedKey]: payer[updatedKey] || "",
    };
    return (
      <Form
        initialValues={initialValues}
        validationSchema={object({
          [updatedKey]: string(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await this.submitChange(values);
            toggleEditMode();
          } catch (err) {
            console.log(err);
          } finally {
            setSubmitting(false);
          }
        }}
        onReset={(_values, { resetForm }) => {
          resetForm(initialValues);
          toggleEditMode();
        }}
        render={({ dirty }) => (
          <>
            <Input
              name={updatedKey}
              size="mini"
              fieldProps={{
                inline: true,
                style: {
                  display: "inline",
                  marginRight: "10px",
                },
              }}
            />
            <FormButton.Submit
              disabled={!dirty}
              positive
              size="mini"
              icon="check"
              style={{
                marginRight: "10px",
              }}
            />
            <FormButton.Reset size="mini" icon="close" />
          </>
        )}
      />
    );
  }
}

export default connect(null, { updatePayer })(UpdatePayerForm);
