import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Message } from "semantic-ui-react";
import { Form, Button } from "formik-semantic-ui";
import { addReportLinks } from "./store/actions/InternalAnalytics";
import { Auth } from "aws-amplify";
import { object, string } from "yup";

const initialValues = {
  facilityIds: "",
};

export class AddFacilityReportLink extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {};
  }

  render() {
    console.log(this.props["reportId"], "props");
    return (
      <Modal
        trigger={<Button positive>Add/Edit Facility Report Links</Button>}
        closeIcon
      >
        <Modal.Header>Add/Edit Facility Report Links</Modal.Header>
        <Modal.Content>
          <Form
            initialValues={{
              ...initialValues,
              facilityIds: this.props["facilityIds"],
              analyticsReportId: this.props["reportId"],
            }}
            validationSchema={object({
              analyticsReportId: string().required(),
              facilityIds: string().required(),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                const authToken = (await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken();
                await this.props.addReportLinks(authToken, values);
                this.setState({ success: true, ...values });
                resetForm(initialValues);
              } catch (err) {
                console.error(err);
                this.setState({
                  success: false,
                  error: err,
                });
                setSubmitting(false);
              }
            }}
            schema={{
              facilityIds: {
                label: "Facility IDs",
                inputProps: { placeholder: "1,2,3,4" },
              },
              analyticsReportId: {
                label: "Report ID",
                inputProps: { placeholder: "" },
              },
            }}
          >
            <Button.Submit>Submit</Button.Submit>
          </Form>
          {this.state.success && (
            <Message success>
              <Message.Header>Facility Report Links Created</Message.Header>
            </Message>
          )}
          {!this.state.success && this.state.error && (
            <Message error>
              <Message.Header>
                Failed with {this.state.error.status} -{" "}
                {this.state.error.statusText}
              </Message.Header>
              <Message.Content>
                <pre style={{ overflow: "scroll" }}>{this.state.error.msg}</pre>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  { addReportLinks }
)(AddFacilityReportLink);
