import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Button } from "semantic-ui-react";
import { Auth } from "aws-amplify";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { deleteMCCE } from "./store/actions";
import UpdateMCCEForm from "./UpdateMCCEForm";

export class MCCEListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  toggleEditMode() {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  }

  async onDeleteConfirm() {
    let authToken;
    try {
      authToken = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken;
    } catch {
      Auth.signOut();
    }
    const { mcce, deleteMCCE } = this.props;
    if (authToken) {
      await deleteMCCE(authToken, mcce);
    }
  }

  render() {
    const { mcce } = this.props;
    const { isEditMode } = this.state;
    const nonEditMode = (
      <>
        <List.Content floated="right">
          <Button
            primary
            size="mini"
            style={{ marginRight: "10px" }}
            onClick={this.toggleEditMode}
          >
            Edit
          </Button>
          <DeleteConfirmationModal
            buttonProps={{ size: "mini" }}
            header="Are you sure you want to delete this MCCE?"
            message={mcce.Description}
            onDeleteConfirm={this.onDeleteConfirm}
          />
        </List.Content>
        <List.Header>
          {mcce.NlpId}: {mcce.Description}
        </List.Header>
      </>
    );
    const editMode = (
      <List.Content>
        <UpdateMCCEForm
          mcceId={mcce.Id}
          description={mcce.Description}
          nlpId={mcce.NlpId}
          toggleEditMode={this.toggleEditMode}
        />
      </List.Content>
    );
    return <List.Item>{isEditMode ? editMode : nonEditMode}</List.Item>;
  }
}

export default connect(
  null,
  { deleteMCCE }
)(MCCEListItem);
