import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { facilitiesSelector } from "./store/selectors";

function FacilityLink({ facility }) {
  const destination = `/insurance/${facility.Id}`;
  return facility ? <Link to={destination}>{facility.Name}</Link> : null;
}

function mapStateToProps(_, initialProps) {
  const { facilityId } = initialProps;
  return state => {
    return {
      facility: facilitiesSelector(state)[facilityId],
    };
  };
}

export default connect(mapStateToProps)(FacilityLink);
