import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchReportLinks } from "./store/actions/InternalAnalytics";
import NewAnalyticsReport from "./NewAnalyticsReport";
import AddFacilityReportLink from "./AddReportLink";
import DeleteAnalyticsReport from "./DeleteAnalyticsReport";

export class AnalyticsReportPage extends Component {
  componentDidMount() {
    const { fetchReportLinks } = this.props;
    typeof fetchReportLinks === "function" && fetchReportLinks();
  }
  render() {
    let { reports } = this.props;
    const items = reports.map((item, key) => (
      <li key={item.id}>
        {item.id} <br />
        Report Name: {item.reportName} <br />
        Facilities: {item.facilities} <br />
        <AddFacilityReportLink
          reportId={item.id}
          facilityIds={item.facilities}
        />
        <DeleteAnalyticsReport reportId={item.id} />
      </li>
    ));
    return (
      <>
        <h1>Analytics Reports</h1>
        <NewAnalyticsReport />
        <ul>{items}</ul>
      </>
    );
  }
}

export function mapStateToProps(state) {
  return state => ({
    reports: Object.values(state.analyticsReports.data),
  });
}

export default connect(
  mapStateToProps,
  { fetchReportLinks }
)(AnalyticsReportPage);
