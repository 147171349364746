import { Auth } from "aws-amplify";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button } from "semantic-ui-react";
import Modal from "@medasync/shared-ui/dist/Modal";
import { etlRunsRunIdDelete } from "./store/actions";

class DeleteEtlRun extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      shouldRedirect: false,
    };
  }

  doLink(path) {
    const { baseRoute = "" } = this.props;
    return `${baseRoute}${path}`;
  }

  openModal(open) {
    this.setState({ open });
  }

  deleteEtlRun() {
    const { runId } = this.props;
    Auth.currentAuthenticatedUser()
      .then(
        data =>
          data.signInUserSession.idToken.jwtToken && etlRunsRunIdDelete(runId)
      )
      .then(data =>
        this.setState({
          ...this.state,
          shouldRedirect: true,
        })
      )
      .catch(err => Auth.signOut());
  }

  render() {
    const { shouldRedirect } = this.state;
    if (shouldRedirect) {
      return (
        <>
          <Redirect to={this.doLink("/")} />
        </>
      );
    }

    return (
      <>
        <Button negative onClick={() => this.openModal(true)}>
          Delete
        </Button>
        <Modal
          open={this.state.open}
          onClose={() => this.openModal(false)}
          title="Delete ETL Run?"
        >
          <p>Are you sure?</p>
          <Button onClick={() => this.openModal(false)}>No</Button>
          <Button onClick={() => this.deleteEtlRun()}>Yes</Button>
        </Modal>
      </>
    );
  }
}

export default connect(
  null,
  { etlRunsRunIdDelete }
)(DeleteEtlRun);
