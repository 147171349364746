import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function InsurancePlanGroupLink({ insurancePlanGroup }) {
  const groupId = encodeURIComponent(insurancePlanGroup.Id);
  const destination = insurancePlanGroup
    ? `/insurancePlanGroups/${groupId}`
    : null;
  return insurancePlanGroup ? (
    <Link to={destination}>{insurancePlanGroup.Name}</Link>
  ) : null;
}

function mapStateToProps(_, initialProps) {
  const { insurancePlanGroupId } = initialProps;
  return state => {
    return {
      insurancePlanGroup: state.insurancePlanGroups.all[insurancePlanGroupId],
    };
  };
}

export default connect(mapStateToProps)(InsurancePlanGroupLink);
