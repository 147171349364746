import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import UpdatePayerForm from "./UpdatePayerForm";

export class PayerLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
  }

  toggleEditMode() {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  }
  render() {
    const { payer } = this.props;
    const payerId = encodeURIComponent(payer.Id);
    const destination = `/payers/${payerId}`;
    const { isEditMode } = this.state;
    const nonEditMode = (
      <>
        <Link to={destination}>{payer.PccName}</Link> -{" "}
        {payer.ComprehendClassifierArn
          ? payer.ComprehendClassifierArn
          : "No ARN ID Set"}
        <Button
          primary
          size="mini"
          style={{ marginLeft: "10px" }}
          onClick={this.toggleEditMode}
        >
          Edit
        </Button>
      </>
    );
    const editMode = (
      <UpdatePayerForm
        payer={payer}
        toggleEditMode={this.toggleEditMode}
        updatedKey="ComprehendClassifierArn"
      />
    );
    return isEditMode ? editMode : nonEditMode;
  }
}

function mapStateToProps(_, initialProps) {
  const { payerId } = initialProps;
  return state => {
    return {
      payer: state.payers.all[payerId],
    };
  };
}

export default connect(mapStateToProps)(PayerLink);
