import { normalize } from "./index";
import { FETCH_FACILITIES } from "../actions/types";

const initialState = {
  all: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_FACILITIES}_FULFILLED`:
      return {
        ...state,
        all: {
          ...state.all,
          ...normalize(action.payload.data),
        },
      };
    default:
      return state;
  }
}
