import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Message } from "semantic-ui-react";
import { Form, Input, Button } from "formik-semantic-ui";
import { insurancePlanGroupPost } from "./store/actions/index";
import { Auth } from "aws-amplify";
import { object, number, string } from "yup";
import { v4 } from "uuid";

const initialValues = {
  Name: "",
  Description: "",
  Rank: 0,
};

export class NewInsurancePlanGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { payerId } = this.props;
    return (
      <Modal trigger={<Button positive>New Plan Group</Button>} closeIcon>
        <Modal.Header>Create a new Plan Group</Modal.Header>
        <Modal.Content>
          <Form
            initialValues={initialValues}
            validationSchema={object({
              Name: string().required(),
              Description: string(),
              Rank: number().required(),
            })}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                const authToken = (await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken();
                await this.props.insurancePlanGroupPost(authToken, {
                  ...values,
                  Payer: payerId,
                  Id: v4(),
                });
                this.setState({ success: true, ...values });
                resetForm(initialValues);
              } catch (err) {
                console.error(err);
                this.setState({
                  success: false,
                  error: err,
                });
                setSubmitting(false);
              }
            }}
            render={() => (
              <>
                <Form.Children>
                  <Input
                    name="Name"
                    label="Name"
                    inputProps={{ placeholder: "My Plan Group" }}
                  />
                  <Input
                    name="Description"
                    label="Description"
                    inputProps={{ placeholder: "Level description" }}
                  />
                  <Input
                    name="Rank"
                    label="Rank"
                    inputProps={{ placeholder: "0" }}
                  />
                </Form.Children>
                <Button.Submit>Submit</Button.Submit>
              </>
            )}
          />
          {this.state.success && (
            <Message success>
              <Message.Header>Plan group successfully created</Message.Header>
              <Message.Content>
                <p>Name: {this.state.Name}</p>
              </Message.Content>
            </Message>
          )}
          {!this.state.success && this.state.error && (
            <Message error>
              <Message.Header>
                Failed with {this.state.error.status} -{" "}
                {this.state.error.statusText}
              </Message.Header>
              <Message.Content>
                <pre style={{ overflow: "scroll" }}>{this.state.error.msg}</pre>
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  { insurancePlanGroupPost }
)(NewInsurancePlanGroup);
