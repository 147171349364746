import { normalize } from "./index";
import { FETCH_ETL_RUNS, SET_ETL_RUNS_SEARCH_DATE } from "../actions/types";

const initialState = {
  byDate: {},
  all: {},
  searchDate: new Date(Date.now()),
};

export default function(state = initialState, action) {
  switch (action.type) {
    case `${FETCH_ETL_RUNS}_FULFILLED`:
      const normalized = normalize(action.payload);
      const newState = {
        ...state,
        all: {
          ...state.all,
          ...normalized,
        },
      };
      newState.byDate[state.searchDate] = Object.keys(normalized);
      return newState;
    case `${SET_ETL_RUNS_SEARCH_DATE}_FULFILLED`:
      return {
        ...state,
        searchDate: action.payload,
      };
    default:
      return state;
  }
}
