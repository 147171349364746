import React from "react";
import { Modal, Button } from "semantic-ui-react";

export const DeleteConfirmationModal = ({
  buttonText = "",
  header = "Are You Sure?",
  message,
  buttonProps,
  onDeleteConfirm,
  disabled,
}) => {
  const { negative = true, ...buttonRest } = buttonProps;
  return (
    <Modal
      trigger={
        <Button negative={negative} disabled={disabled} {...buttonRest}>
          {`Delete ${buttonText}`}
        </Button>
      }
      basic
      content={message}
      header={header}
      actions={[
        {
          key: "yes",
          content: "Yes",
          negative: true,
          onClick: () => {
            onDeleteConfirm &&
              typeof onDeleteConfirm === "function" &&
              onDeleteConfirm();
          },
        },
        "No",
      ]}
    />
  );
};

export default DeleteConfirmationModal;
