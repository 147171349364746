import React, { useState, useEffect } from "react";
import { Pagination } from "semantic-ui-react";
import { chunk } from "lodash";

export default ({ dataToPaginate = [], pageSize, setActivePageData }) => {
  const [activePage, setActivePage] = useState(1);

  const totalPages = Math.ceil(dataToPaginate.length / pageSize);
  const pages = chunk(dataToPaginate, pageSize);

  useEffect(() => {
    const pageToShow = pages[activePage - 1] ? pages[activePage - 1] : pages[0];
    setActivePageData(pageToShow);
  }, [activePage, dataToPaginate.length]);

  const onPageChange = (e, pageInfo) => {
    setActivePage(pageInfo.activePage);
  };

  return (
    dataToPaginate.length > pageSize && (
      <Pagination
        activePage={activePage}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    )
  );
};
