import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import DeleteEtlRun from "./DeleteEtlRun";
import Table from "@medasync/shared-ui/dist/Table";
import { etlRunsRunIdErrorsGet } from "./store/actions";
import { etlErrorsByEtlRunSelector } from "./store/selectors";

// ETL errors table
const config = [
  {
    title: "Sort Key",
    key: "Id",
  },
  {
    title: "Exception Class",
    key: "ExceptionClass",
  },
  {
    title: "Description",
    key: "Description",
  },
  {
    title: "Traceback",
    key: "Traceback",
  },
  {
    title: "Code File",
    key: "File",
  },
  {
    title: "Date/Time",
    key: "Datetime",
  },
];

export class EtlRunPage extends Component {
  componentWillMount() {
    const { etlRun = {}, etlRunsRunIdErrorsGet } = this.props;
    if (typeof etlRunsRunIdErrorsGet === "function") {
      Auth.currentAuthenticatedUser()
        .then(
          data =>
            data.signInUserSession.idToken.jwtToken &&
            etlRunsRunIdErrorsGet(etlRun.Id)
        )
        .catch(err => Auth.signOut());
    }
  }

  render() {
    const { etlErrors, etlRun = {} } = this.props;
    return (
      <>
        <h3>{etlRun.Id}</h3>
        <DeleteEtlRun runId={etlRun.Id} />
        <p>
          <b>Report Type: </b>
          {etlRun.ReportType}
        </p>
        <p>
          <b>Number of Records: </b>
          {etlRun.NumRecords}
        </p>
        <p>
          <b>Status: </b>
          {etlRun.Status}
        </p>
        <p>
          <b>Date/Time: </b>
          {etlRun.Datetime}
        </p>
        <Table config={config} data={etlErrors} rowStyler={data => {}} />
      </>
    );
  }
}

EtlRunPage.propTypes = {
  etlRun: PropTypes.object,
  etlErrors: PropTypes.array,
};

function mapStateToProps(state, initialProps) {
  const {
    match: {
      params: { id },
    },
  } = initialProps;
  const etlRunId = decodeURIComponent(id);
  return {
    etlRun: state.etlRuns.all[etlRunId],
    etlErrors: etlErrorsByEtlRunSelector(state, etlRunId),
  };
}

export default connect(
  mapStateToProps,
  { etlRunsRunIdErrorsGet }
)(EtlRunPage);
